import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import * as dayjs from 'dayjs';

import { Endpoint } from '../constants/endpoint';

import {
  BFFCustomerTokenResponseModel,
  CustomerByCriteriaRequestModel,
  CustomerByCriteriaResponseModel,
  CustomerByIdResponseModel,
  DeleteCustomerRequestModel,
  DeleteCustomerResponseModel,
  ExportCustomerRequestModel,
  HistoryCustomerResponseModel,
  PatchCustomerRequestModel,
  PatchCustomerResponseModel,
} from '../../models/customer.model';

import { TransformTool } from '../tools/transform.tool';
import { ContextKey } from '../constants/context';

@Injectable({
  providedIn: 'root',
})
export class CustomerFetchService {
  constructor(private httpClient: HttpClient) {}

  public get(id: string): Observable<CustomerByIdResponseModel> {
    return this.httpClient.get<CustomerByIdResponseModel>(
      `${Endpoint.Customer.Customer.Common}/${id}`
    );
  }

  public criteria(
    query: CustomerByCriteriaRequestModel
  ): Observable<CustomerByCriteriaResponseModel> {
    return this.httpClient.get<CustomerByCriteriaResponseModel>(
      `${
        Endpoint.Customer.Customer.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public patch(
    id: string,
    data: PatchCustomerRequestModel
  ): Observable<PatchCustomerResponseModel> {
    return this.httpClient.patch<PatchCustomerResponseModel>(
      `${Endpoint.Customer.Customer.Common}/${id}`,
      data
    );
  }

  public delete(
    id: string,
    query: DeleteCustomerRequestModel
  ): Observable<DeleteCustomerResponseModel> {
    return this.httpClient.delete<DeleteCustomerResponseModel>(
      `${
        Endpoint.Customer.Customer.Common
      }/${id}${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public export(query: ExportCustomerRequestModel): Observable<{
    content_type: string;
    enable_range_processing: boolean;
    entity_tag: string | null;
    file_contents: string;
    file_download_name: string;
    last_modified: string | null;
  }> {
    return this.httpClient.get<{
      content_type: string;
      enable_range_processing: boolean;
      entity_tag: string | null;
      file_contents: string;
      file_download_name: string;
      last_modified: string | null;
    }>(
      `${
        Endpoint.Customer.Customer.Export
      }${TransformTool.ConvertObjectToQueryString(query)}`,
      {
        headers: {
          'Content-Type': '*/csv',
          FileName: `${dayjs().format('YYYYMMDDHHmmssSSS')}.csv`,
          'X-Timezone-Offset': dayjs()
            .format('Z')
            .replace('+', '')
            .replace(':', '.'),
        },
        context: new HttpContext().set(ContextKey.ApiEncryption, false),
      }
    );
  }

  public history(id: string): Observable<HistoryCustomerResponseModel> {
    return this.httpClient.get<HistoryCustomerResponseModel>(
      `${Endpoint.Customer.Customer.History}/${id}`
    );
  }

  public bffCustomerToken(
    refCode: string
  ): Observable<BFFCustomerTokenResponseModel> {
    return this.httpClient.get<BFFCustomerTokenResponseModel>(
      `${Endpoint.Customer.BFF.CustomerToken}/${refCode}`
    );
  }
}

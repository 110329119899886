import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { environment } from '../environments/environment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const scripts: any;

@Component({
  selector: 'xbaht-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(private title: Title) {
    if (!environment.production) {
      this.title.setTitle(
        `${this.title.getTitle()} (v${environment.version} [${
          environment.mode
        }])`
      );
    }
  }

  ngOnInit(): void {
    scripts.initLayout();

    setTimeout(() => {
      scripts.initLayout();
    }, 300);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  IDkollenBankIDAuthenticationRequestModel,
  IDkollenBankIDAuthenticationResponseModel,
  IDkollenBankIDCancelRequestModel,
  IDkollenBankIDCancelResponseModel,
  IDkollenBankIDCollectResponseModel,
} from '../../models/idkollen.model';

@Injectable({
  providedIn: 'root',
})
export class IDkollenFetchService {
  constructor(private httpClient: HttpClient) {}

  public bankIDAuthentication(
    data: IDkollenBankIDAuthenticationRequestModel
  ): Observable<IDkollenBankIDAuthenticationResponseModel> {
    return this.httpClient.post<IDkollenBankIDAuthenticationResponseModel>(
      `${Endpoint.IdentityConnect.IDkollen.Authentication}`,
      data
    );
  }

  public bankIDCollect(
    refCode: string
  ): Observable<IDkollenBankIDCollectResponseModel> {
    return this.httpClient.get<IDkollenBankIDCollectResponseModel>(
      `${Endpoint.IdentityConnect.IDkollen.Collect}/${refCode}`
    );
  }

  public bankIDCancel(
    data: IDkollenBankIDCancelRequestModel
  ): Observable<IDkollenBankIDCancelResponseModel> {
    return this.httpClient.post<IDkollenBankIDCancelResponseModel>(
      `${Endpoint.IdentityConnect.IDkollen.Cancel}`,
      data
    );
  }
}

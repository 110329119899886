/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ContextKey } from '../constants/context';

@Injectable({
  providedIn: 'root',
})
export class LoggingFetchService implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const start = Date.now();

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const elapsed = Date.now() - start;

          console.groupCollapsed('Fetch:', req.method, req.url, `${elapsed}ms`);
          console.log('%cELAPSED\t\t:', 'color: #885544', `${elapsed}ms`);
          console.log('%cMETHOD\t\t:', 'color: #449900', req.method);
          console.log('URL\t\t\t:', req.urlWithParams);
          console.log(
            '\t\t\t>',
            '[Version]\t\t\t:',
            req.context.get(ContextKey.ApiVersion) || '1.0'
          );
          console.log(
            '\t\t\t>',
            '[Language]\t\t:',
            req.headers.get('Language')
          );
          console.log(
            '\t\t\t>',
            '[Authorization]\t:',
            req.headers.get('Authorization')
          );
          console.log('%cBODY\t\t:', 'color: #d2691e', req.body);
          console.log('%cRESPONSE\t:', 'color: #0099ff', event.body);
          console.groupEnd();
        }
      }),
      catchError((err) => throwError(() => err))
    );
  }
}

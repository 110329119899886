import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { catchError, first, firstValueFrom, map, throwError } from 'rxjs';

import { Store } from '@ngrx/store';

import { MasterFetchService } from '../../../fetches/services/master-fetch.service';
import { NgRxState } from '../../../modules/app-ngrx.module';
import { MasterAction } from '../../../stored/global/actions';

@Directive({
  selector: '[xbahtMasterExpression]',
})
export class MasterExpressionDirective implements OnInit {
  constructor(
    private ngrxStore: Store<NgRxState>,
    private templateRef: TemplateRef<{
      $implicit: {
        id: string;
        name: string;
        operators: string[];
        value: boolean | number;
      };
      index: number;
      first: boolean;
      last: boolean;
      even: boolean;
      odd: boolean;
      count: number;
    }>,
    private viewContainer: ViewContainerRef,
    private masterFetchService: MasterFetchService
  ) {}

  async ngOnInit(): Promise<void> {
    const expressions = await firstValueFrom(
      this.ngrxStore.select('Master').pipe(map((item) => item.expressions))
    );
    if (expressions.length != 0) {
      for (let index = 0; index < expressions.length; index++) {
        this.viewContainer.createEmbeddedView(this.templateRef, {
          $implicit: expressions[index],
          index,
          first: index === 0,
          last: index === expressions.length - 1,
          even: (index & 1) === 0,
          odd: (index & 1) === 1,
          count: expressions.length,
        });
      }
    } else {
      this.masterFetchService
        .expression({})
        .pipe(
          first(),
          catchError((error) => throwError(() => error))
        )
        .subscribe((item) => {
          if (item.status && item.payload) {
            this.ngrxStore.dispatch(
              MasterAction.SetExpression({ expressions: item.payload })
            );

            this.viewContainer.clear();
            for (let index = 0; index < item.payload.length; index++) {
              this.viewContainer.createEmbeddedView(this.templateRef, {
                $implicit: item.payload[index],
                index,
                first: index === 0,
                last: index === item.payload.length - 1,
                even: (index & 1) === 0,
                odd: (index & 1) === 1,
                count: item.payload?.length,
              });
            }
          }
        });
    }
  }
}

import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EMPTY, tap } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { APP_SET_LANGUAGE, APP_SET_START } from '../actions/app.action';

@Injectable({
  providedIn: 'root',
})
export class AppEffect {
  constructor(
    private actions$: Actions,
    private translateService: TranslateService
  ) {}

  private appSetStartEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(APP_SET_START),
      // tap((value) => console.log(`${APP_SET_START}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private appSetLanguageEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(APP_SET_LANGUAGE),
      // tap((value) => console.log(`${APP_SET_LANGUAGE}:`, value)),
      tap((value: { language: string }) =>
        this.translateService.use(value?.language || 'en')
      ),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  HistoryApplicationResponseModel,
  PatchApplicationRequestModel,
  PatchApplicationResponseModel,
  ApplicationByCriteriaRequestModel,
  ApplicationByCriteriaResponseModel,
} from '../../models/application.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class ApplicationFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: ApplicationByCriteriaRequestModel
  ): Observable<ApplicationByCriteriaResponseModel> {
    return this.httpClient.get<ApplicationByCriteriaResponseModel>(
      `${
        Endpoint.Configuration.Application.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public patch(
    id: string,
    data: PatchApplicationRequestModel
  ): Observable<PatchApplicationResponseModel> {
    return this.httpClient.patch<PatchApplicationResponseModel>(
      `${Endpoint.Configuration.Application.Common}/${id}`,
      data
    );
  }

  public history(id: string): Observable<HistoryApplicationResponseModel> {
    return this.httpClient.get<HistoryApplicationResponseModel>(
      `${Endpoint.Configuration.Application.History}/${id}`
    );
  }
}

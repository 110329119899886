import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EMPTY } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
  AUTH_SET_EMAIL,
  AUTH_SET_VERIFIED,
  AUTH_RESET_ALL,
  AUTH_SET_INFO,
  AUTH_SET_REMEMBERED,
  AUTH_SET_PERMISSION,
  AUTH_SET_TOKEN,
} from '../actions/auth.action';

@Injectable({
  providedIn: 'root',
})
export class AuthEffect {
  constructor(private actions$: Actions) {}

  private authSetEmailEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_SET_EMAIL),
      // tap((value) => console.log(`${AUTH_SET_EMAIL}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private authSetVerifiedEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_SET_VERIFIED),
      // tap((value) => console.log(`${AUTH_SET_VERIFIED}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private authSetRememberedEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_SET_REMEMBERED),
      // tap((value) => console.log(`${AUTH_SET_REMEMBERED}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private authSetInfoEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_SET_INFO),
      // tap((value) => console.log(`${AUTH_SET_INFO}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private authSetPermissionEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_SET_PERMISSION),
      // tap((value) => console.log(`${AUTH_SET_PERMISSION}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private authSetTokenEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_SET_TOKEN),
      // tap((value) => console.log(`${AUTH_SET_TOKEN}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private authResetAllEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_RESET_ALL),
      // tap((value) => console.log(`${AUTH_RESET_ALL}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );
}

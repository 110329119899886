import { Action, createReducer, on } from '@ngrx/store';

import { AppAction } from '../actions';
import { AppState } from '../states/app.state';

import * as dayjs from 'dayjs';

const initialState: AppState = {
  ready: false,
  language: '',
  timestamp: '',
};

const appReducer = createReducer(
  initialState,
  on(AppAction.SetStart, (state) => ({
    ...state,
    ready: true,
    timestamp: dayjs().format(),
  })),
  on(AppAction.SetLanguage, (state, { language }) => ({
    ...state,
    language,
  }))
);

export function AppReducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { NgRxState } from '@xbaht/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  constructor(private router: Router, private ngrxStore: Store<NgRxState>) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return combineLatest([this.ngrxStore.select('Auth')]).pipe(
      map(([auth]) => {
        if (auth.verified) {
          if (state.url.indexOf('/auth') > -1) {
            this.router.navigate([''], { replaceUrl: true });

            return false;
          } else {
            auth.permissions.forEach((value) =>
              value.permissions.forEach((perm) => {
                if (perm.url === state.url) {
                  if (!perm.accesses.includes('view')) {
                    this.router.navigate([''], { replaceUrl: true });
                  }
                }
              })
            );

            return true;
          }
        } else {
          if (state.url.indexOf('/auth') < 0) {
            this.router.navigate(['', 'auth', 'sign-in'], { replaceUrl: true });

            return false;
          } else {
            if (state.url === '/auth/2fa') {
              if (auth.email && !auth.verified) {
                return true;
              } else {
                this.router.navigate(['', 'auth', 'sign-in'], {
                  replaceUrl: true,
                });

                return false;
              }
            }

            return true;
          }
        }
      })
    );
  }
}

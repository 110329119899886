import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  CreatePayoutPartnerRequestModel,
  CreatePayoutPartnerResponseModel,
  HistoryPayoutPartnerResponseModel,
  PatchPayoutPartnerRequestModel,
  PatchPayoutPartnerResponseModel,
  PayoutPartnerByCriteriaRequestModel,
  PayoutPartnerByCriteriaResponseModel,
  UpdatePayoutPartnerRequestModel,
  UpdatePayoutPartnerResponseModel,
} from '../../models/payout-partner.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class PayoutPartnerFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: PayoutPartnerByCriteriaRequestModel
  ): Observable<PayoutPartnerByCriteriaResponseModel> {
    return this.httpClient.get<PayoutPartnerByCriteriaResponseModel>(
      `${
        Endpoint.Configuration.PayoutPartner.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public create(
    data: CreatePayoutPartnerRequestModel
  ): Observable<CreatePayoutPartnerResponseModel> {
    return this.httpClient.post<CreatePayoutPartnerResponseModel>(
      Endpoint.Configuration.PayoutPartner.Common,
      data
    );
  }

  public update(
    id: string,
    data: UpdatePayoutPartnerRequestModel
  ): Observable<UpdatePayoutPartnerResponseModel> {
    return this.httpClient.put<UpdatePayoutPartnerResponseModel>(
      `${Endpoint.Configuration.PayoutPartner.Common}/${id}`,
      data
    );
  }

  public patch(
    id: string,
    data: PatchPayoutPartnerRequestModel
  ): Observable<PatchPayoutPartnerResponseModel> {
    return this.httpClient.patch<PatchPayoutPartnerResponseModel>(
      `${Endpoint.Configuration.PayoutPartner.Common}/${id}`,
      data
    );
  }

  public history(id: string): Observable<HistoryPayoutPartnerResponseModel> {
    return this.httpClient.get<HistoryPayoutPartnerResponseModel>(
      `${Endpoint.Configuration.PayoutPartner.History}/${id}`
    );
  }
}

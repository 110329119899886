import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FindPipe } from '../pipes/find/find.pipe';
import { WordPipe } from '../pipes/word/word.pipe';

@NgModule({
  declarations: [FindPipe, WordPipe],
  imports: [CommonModule],
  exports: [FindPipe, WordPipe],
})
export class AppPipeModule {}

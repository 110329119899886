import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  CreateUserRequestModel,
  CreateUserResponseModel,
  CurrentUserResponseModel,
  HistoryUserResponseModel,
  PatchUserRequestModel,
  PatchUserResponseModel,
  UpdateUserRequestModel,
  UpdateUserResponseModel,
  UserByCriteriaRequestModel,
  UserByCriteriaResponseModel,
  UserByIdResponseModel,
} from '../../models/user.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class UserFetchService {
  constructor(private httpClient: HttpClient) {}

  public current(): Observable<CurrentUserResponseModel> {
    return this.httpClient.get<CurrentUserResponseModel>(
      Endpoint.Identity.User.Current
    );
  }

  public read(id: string): Observable<UserByIdResponseModel> {
    return this.httpClient.get<UserByIdResponseModel>(
      `${Endpoint.Identity.User.Common}/${id}`
    );
  }

  public criteria(
    query: UserByCriteriaRequestModel
  ): Observable<UserByCriteriaResponseModel> {
    return this.httpClient.get<UserByCriteriaResponseModel>(
      `${
        Endpoint.Identity.User.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public create(
    data: CreateUserRequestModel
  ): Observable<CreateUserResponseModel> {
    return this.httpClient.post<CreateUserResponseModel>(
      Endpoint.Identity.User.Common,
      data
    );
  }

  public update(
    id: string,
    data: UpdateUserRequestModel
  ): Observable<UpdateUserResponseModel> {
    return this.httpClient.put<UpdateUserResponseModel>(
      `${Endpoint.Identity.User.Common}/${id}`,
      data
    );
  }

  public patch(
    id: string,
    data: PatchUserRequestModel
  ): Observable<PatchUserResponseModel> {
    return this.httpClient.patch<PatchUserResponseModel>(
      `${Endpoint.Identity.User.Common}/${id}`,
      data
    );
  }

  public history(id: string): Observable<HistoryUserResponseModel> {
    return this.httpClient.get<HistoryUserResponseModel>(
      `${Endpoint.Identity.User.History}/${id}`
    );
  }
}

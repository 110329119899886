/* eslint-disable @typescript-eslint/no-explicit-any */
export class TransformTool {
  public static ConvertObjectToQueryString(values: any) {
    if (!values) {
      return '';
    }

    const query = Object.keys(values)
      .map((key) =>
        values[key] !== null && values[key] !== undefined && values[key] !== ''
          ? key + '=' + values[key]
          : null
      )
      .filter((value) => value)
      .join('&');

    return query ? '?' + query : '';
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  AuthenticatorCodeRequestModel,
  AuthenticatorCodeResponseModel,
  AuthenticatorKeyResponseModel,
  ForgotPasswordRequestModel,
  ForgotPasswordResponseModel,
  PermissionResponseModel,
  ResetPasswordRequestModel,
  ResetPasswordResponseModel,
  SignInRequestModel,
  SignInResponseModel,
  SignOutResponseModel,
  TokenResponseModel,
  VerifyResponseModel,
} from '../../models/authentication.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationFetchService {
  constructor(private httpClient: HttpClient) {}

  public signIn(data: SignInRequestModel): Observable<SignInResponseModel> {
    return this.httpClient.post<SignInResponseModel>(
      Endpoint.Identity.Authentication.SignIn,
      data
    );
  }

  public authenticatorKey(): Observable<AuthenticatorKeyResponseModel> {
    return this.httpClient.get<AuthenticatorKeyResponseModel>(
      Endpoint.Identity.Authentication.AuthenticatorKey
    );
  }

  public authenticatorCode(
    data: AuthenticatorCodeRequestModel
  ): Observable<AuthenticatorCodeResponseModel> {
    return this.httpClient.post<AuthenticatorCodeResponseModel>(
      Endpoint.Identity.Authentication.AuthenticatorCode,
      data
    );
  }

  public verify(): Observable<VerifyResponseModel> {
    return this.httpClient.get<VerifyResponseModel>(
      Endpoint.Identity.Authentication.Verify
    );
  }

  public permission(): Observable<PermissionResponseModel> {
    return this.httpClient.get<PermissionResponseModel>(
      Endpoint.Identity.Authentication.Permission
    );
  }

  public token(): Observable<TokenResponseModel> {
    return this.httpClient.get<TokenResponseModel>(
      Endpoint.Identity.Authentication.Token
    );
  }

  public resetPassword(
    data: ResetPasswordRequestModel
  ): Observable<ResetPasswordResponseModel> {
    return this.httpClient.post<ResetPasswordResponseModel>(
      Endpoint.Identity.Authentication.ResetPassword,
      data
    );
  }

  public forgotPassword(
    data: ForgotPasswordRequestModel
  ): Observable<ForgotPasswordResponseModel> {
    return this.httpClient.post<ForgotPasswordResponseModel>(
      Endpoint.Identity.Authentication.ForgotPassword,
      data
    );
  }

  public signOut(): Observable<SignOutResponseModel> {
    return this.httpClient.post<SignOutResponseModel>(
      Endpoint.Identity.Authentication.SignOut,
      null
    );
  }
}

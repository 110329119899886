import { createAction, props } from '@ngrx/store';

export const MASTER_SET_COUNTRY = '[MASTER][SET]::[Country]';
export const MASTER_SET_CURRENCY = '[MASTER][SET]::[Currency]';
export const MASTER_SET_PAYOUT_METHOD = '[MASTER][SET]::[PayoutMethod]';
export const MASTER_SET_PARTNER = '[MASTER][SET]::[Partner]';
export const MASTER_SET_EXPRESSION = '[MASTER][SET]::[Expression]';
export const MASTER_SET_PAYMENT_METHOD = '[MASTER][SET]::[PaymentMethod]';
export const MASTER_SET_MONTHLY_PAYMENT = '[MASTER][SET]::[MonthlyPayment]';
export const MASTER_SET_OCCUPATION = '[MASTER][SET]::[Occupation]';
export const MASTER_SET_PURPOSE = '[MASTER][SET]::[Purpose]';
export const MASTER_SET_REGULARITY = '[MASTER][SET]::[Regularity]';
export const MASTER_SET_RISK_LEVEL = '[MASTER][SET]::[RiskLevel]';
export const MASTER_SET_CUSTOMER_TAG = '[MASTER][SET]::[CustomerTag]';
export const MASTER_SET_UPDATE_REQUEST = '[MASTER][SET]::[UpdateRequest]';
export const MASTER_SET_TIME_REQUEST = '[MASTER][SET]::[TimeRequest]';
export const MASTER_SET_DOCUMENT_REQUEST = '[MASTER][SET]::[DocumentRequest]';

export const SetCountry = createAction(
  MASTER_SET_COUNTRY,
  props<{ countries: { id: string; name: string; code: string }[] }>()
);
export const SetCurrency = createAction(
  MASTER_SET_CURRENCY,
  props<{ currencies: { name: string; code: string }[] }>()
);
export const SetPayoutMethod = createAction(
  MASTER_SET_PAYOUT_METHOD,
  props<{
    payout_methods: {
      id: string;
      name: string;
      bank_options: { type: 'BBAN' | 'IBAN'; branch: boolean }[];
    }[];
  }>()
);
export const SetPartner = createAction(
  MASTER_SET_PARTNER,
  props<{ partners: { id: string; name: string; options: string }[] }>()
);
export const SetExpression = createAction(
  MASTER_SET_EXPRESSION,
  props<{
    expressions: {
      id: string;
      name: string;
      operators: string[];
      value: boolean | number;
    }[];
  }>()
);
export const SetPaymentMethod = createAction(
  MASTER_SET_PAYMENT_METHOD,
  props<{
    payment_methods: {
      id: string;
      payment: string;
      partner: string;
      code: string;
      sequence: number;
    }[];
  }>()
);
export const SetMonthlyPayment = createAction(
  MASTER_SET_MONTHLY_PAYMENT,
  props<{
    monthly_payments: {
      id: string;
      sequence: number;
      code: string;
      country: string;
      symbol: string;
      description: string;
      incomes?: { max: number; min: number }[] | null;
      sendings?: { max: number; min: number }[] | null;
    }[];
  }>()
);
export const SetOccupation = createAction(
  MASTER_SET_OCCUPATION,
  props<{
    occupations: {
      id: string;
      sequence: number;
      name: string;
      provider_code: { ripple: string; dee_money: string };
      description: string;
    }[];
  }>()
);
export const SetPurpose = createAction(
  MASTER_SET_PURPOSE,
  props<{
    purposes: {
      id: string;
      sequence: number;
      name: string;
      provider_code: { ripple: string; dee_money: string };
      description: string;
    }[];
  }>()
);
export const SetRegularity = createAction(
  MASTER_SET_REGULARITY,
  props<{
    reguarities: {
      id: string;
      sequence: number;
      name: string;
      description: string;
    }[];
  }>()
);
export const SetRiskLevel = createAction(
  MASTER_SET_RISK_LEVEL,
  props<{
    risk_levels: {
      id: string;
      code: string;
      sequence: number;
      level: number;
      name: string;
      description: string;
    }[];
  }>()
);
export const SetCustomerTag = createAction(
  MASTER_SET_CUSTOMER_TAG,
  props<{
    customer_tags: {
      id: string;
      code: string;
      sequence: number;
      name: string;
      description: string;
    }[];
  }>()
);
export const SetUpdateRequest = createAction(
  MASTER_SET_UPDATE_REQUEST,
  props<{
    update_requests: {
      id: string;
      code: string;
      sequence: number;
      name: string;
      description: string;
    }[];
  }>()
);
export const SetTimeRequest = createAction(
  MASTER_SET_TIME_REQUEST,
  props<{
    time_requests: {
      id: string;
      code: string;
      sequence: number;
      name: string;
      description: string;
    }[];
  }>()
);
export const SetDocumentRequest = createAction(
  MASTER_SET_DOCUMENT_REQUEST,
  props<{
    document_requests: {
      id: string;
      code: string;
      sequence: number;
      name: string;
      description: string;
    }[];
  }>()
);

import { Action, createReducer, on } from '@ngrx/store';

import { AuthAction } from '../actions';
import { AuthState } from '../states/auth.state';

const initialState: AuthState = {
  firstname: '',
  lastname: '',
  email: '',
  roles: [],
  permissions: [],
  verified: false,
  remembered: false,
  token: '',
};

const authReducer = createReducer(
  initialState,
  on(AuthAction.SetEmail, (state, { email }) => ({ ...state, email })),
  on(AuthAction.SetVerified, (state, { verified }) => ({ ...state, verified })),
  on(AuthAction.SetRemembered, (state, { remembered }) => ({
    ...state,
    remembered,
  })),
  on(AuthAction.SetInfo, (state, { firstname, lastname, email, roles }) => ({
    ...state,
    firstname,
    lastname,
    email,
    roles,
  })),
  on(AuthAction.SetPermission, (state, { permissions }) => ({
    ...state,
    permissions,
  })),
  on(AuthAction.SetToken, (state, { token }) => ({
    ...state,
    token,
  })),
  on(AuthAction.ResetAll, () => initialState)
);

export function AuthReducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}

import { APP_INITIALIZER, FactoryProvider, Injectable } from '@angular/core';

import { catchError, first, forkJoin, Observable, throwError } from 'rxjs';

import { Store } from '@ngrx/store';

import { AuthenticationFetchService } from '@xbaht/common/fetches';

import { Action, NgRxState } from '@xbaht/common';
import { VerifyResponseModel } from '@xbaht/common/models';

import { CookieStorage, LocalStorage } from './utils/constants/storage';

import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';

export function initialize(
  initializeService: InitializeService
): () => Observable<void> {
  return () => initializeService.initialize();
}

@Injectable({
  providedIn: 'root',
})
export class InitializeService {
  constructor(
    private ngrxStore: Store<NgRxState>,
    private cookieService: CookieService,
    private authenticationFetchService: AuthenticationFetchService
  ) {}

  public initialize(): Observable<void> {
    return new Observable((subscriber) => {
      if (!environment.production) {
        console.log(
          `App Start!. (XBaht: v${environment.version} [${
            !environment.production ? environment.mode : ''
          }])`
        );
      }

      this.ngrxStore.dispatch(Action.AppAction.SetStart());

      const language = localStorage.getItem(LocalStorage.Language);
      this.ngrxStore.dispatch(
        Action.AppAction.SetLanguage({ language: language || 'en' })
      );

      const remember = localStorage.getItem(LocalStorage.Remember);
      this.ngrxStore.dispatch(
        Action.AuthAction.SetRemembered({ remembered: remember ? true : false })
      );

      this.authenticationFetchService
        .verify()
        .pipe(
          first(),
          catchError((error) => throwError(() => error))
        )
        .subscribe({
          next: (response: VerifyResponseModel) => {
            if (response.status) {
              this.ngrxStore.dispatch(
                Action.AuthAction.SetVerified({
                  verified: response.payload?.verified || false,
                })
              );

              if (response.payload?.verified) {
                // this.authenticationFetchService
                //   .permission()
                //   .pipe(
                //     first(),
                //     catchError((error) => throwError(() => error))
                //   )
                //   .subscribe({
                //     next: (response: PermissionResponseModel) => {
                //       if (response.status) {
                //         this.ngrxStore.dispatch(
                //           Action.AuthAction.SetPermission({
                //             permissions: response.payload || [],
                //           })
                //         );
                //       }

                //       subscriber.complete();
                //     },
                //     error: () => subscriber.complete(),
                //   });

                forkJoin([
                  this.authenticationFetchService.permission(),
                  this.authenticationFetchService.token(),
                ])
                  .pipe(
                    first(),
                    catchError((error) => throwError(() => error))
                  )
                  .subscribe({
                    next: ([permission, token]) => {
                      if (permission.status && token.status) {
                        this.ngrxStore.dispatch(
                          Action.AuthAction.SetPermission({
                            permissions: permission.payload || [],
                          })
                        );

                        this.ngrxStore.dispatch(
                          Action.AuthAction.SetToken({
                            token: token.payload?.token || '',
                          })
                        );

                        this.cookieService.set(
                          CookieStorage.token,
                          token.payload?.token || '',
                          {
                            domain: location.hostname,
                            path: '/',
                            expires: 1,
                            secure: true,
                            sameSite: 'None',
                          }
                        );
                      }

                      subscriber.complete();
                    },
                    error: () => subscriber.complete(),
                  });

                // const token = this.cookieService.get(CookieStorage.token);
                // if (token) {
                //   this.ngrxStore.dispatch(
                //     Action.AuthAction.SetToken({ token })
                //   );
                // }
              } else {
                subscriber.complete();
              }
            }
          },
          error: () => subscriber.complete(),
        });
    });
  }
}

export const InitializeProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initialize,
  deps: [InitializeService],
  multi: true,
};

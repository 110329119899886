import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  HistorySystemLimitResponseModel,
  PatchSystemLimitRequestModel,
  PatchSystemLimitResponseModel,
  SystemLimitByCriteriaRequestModel,
  SystemLimitByCriteriaResponseModel,
} from '../../models/system-limit.model';

import { TransformTool } from '../tools/transform.tool';
import { ContextKey } from '../constants/context';

@Injectable({
  providedIn: 'root',
})
export class SystemLimitFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: SystemLimitByCriteriaRequestModel
  ): Observable<SystemLimitByCriteriaResponseModel> {
    return this.httpClient.get<SystemLimitByCriteriaResponseModel>(
      `${
        Endpoint.Configuration.SystemLimit.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`,
      { context: new HttpContext().set(ContextKey.ApiVersion, '1.1') }
    );
  }

  public patch(
    countryId: string,
    configurationId: string,
    data: PatchSystemLimitRequestModel
  ): Observable<PatchSystemLimitResponseModel> {
    return this.httpClient.patch<PatchSystemLimitResponseModel>(
      `${Endpoint.Configuration.SystemLimit.Common}/${countryId}/${configurationId}`,
      data
    );
  }

  public history(
    countryId: string,
    configurationId: string
  ): Observable<HistorySystemLimitResponseModel> {
    return this.httpClient.get<HistorySystemLimitResponseModel>(
      `${Endpoint.Configuration.SystemLimit.History}/${countryId}/${configurationId}`
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  CreateTransactionFeeRequestModel,
  CreateTransactionFeeResponseModel,
  HistoryTransactionFeeResponseModel,
  TransactionFeeByCriteriaRequestModel,
  TransactionFeeByCriteriaResponseModel,
  UpdateTransactionFeeRequestModel,
  UpdateTransactionFeeResponseModel,
} from '../../models/transaction-fee.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class TransactionFeeFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: TransactionFeeByCriteriaRequestModel
  ): Observable<TransactionFeeByCriteriaResponseModel> {
    return this.httpClient.get<TransactionFeeByCriteriaResponseModel>(
      `${
        Endpoint.Configuration.TransactionFee.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public create(
    data: CreateTransactionFeeRequestModel
  ): Observable<CreateTransactionFeeResponseModel> {
    return this.httpClient.post<CreateTransactionFeeResponseModel>(
      Endpoint.Configuration.TransactionFee.Common,
      data
    );
  }

  public update(
    id: string,
    data: UpdateTransactionFeeRequestModel
  ): Observable<UpdateTransactionFeeResponseModel> {
    return this.httpClient.put<UpdateTransactionFeeResponseModel>(
      `${Endpoint.Configuration.TransactionFee.Common}/${id}`,
      data
    );
  }

  public history(id: string): Observable<HistoryTransactionFeeResponseModel> {
    return this.httpClient.get<HistoryTransactionFeeResponseModel>(
      `${Endpoint.Configuration.TransactionFee.History}/${id}`
    );
  }
}

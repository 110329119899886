import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  CampaignByCriteriaRequestModel,
  CampaignByCriteriaResponseModel,
  CreateCampaignRequestModel,
  CreateCampaignResponseModel,
  HistoryCampaignResponseModel,
  PatchCampaignRequestModel,
  PatchCampaignResponseModel,
} from '../../models/campaign.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class CampaignFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: CampaignByCriteriaRequestModel
  ): Observable<CampaignByCriteriaResponseModel> {
    return this.httpClient.get<CampaignByCriteriaResponseModel>(
      `${
        Endpoint.Configuration.Campaign.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public create(
    data: CreateCampaignRequestModel
  ): Observable<CreateCampaignResponseModel> {
    return this.httpClient.post<CreateCampaignResponseModel>(
      Endpoint.Configuration.Campaign.Common,
      data
    );
  }

  // public update(
  //   id: string,
  //   data: UpdateCampaignRequestModel
  // ): Observable<UpdateCampaignResponseModel> {
  //   return this.httpClient.put<UpdateCampaignResponseModel>(
  //     `${Endpoint.Configuration.Campaign.Common}/${id}`,
  //     data
  //   );
  // }

  public patch(
    id: string,
    data: PatchCampaignRequestModel
  ): Observable<PatchCampaignResponseModel> {
    return this.httpClient.patch<PatchCampaignResponseModel>(
      `${Endpoint.Configuration.Campaign.Common}/${id}`,
      data
    );
  }

  public history(id: string): Observable<HistoryCampaignResponseModel> {
    return this.httpClient.get<HistoryCampaignResponseModel>(
      `${Endpoint.Configuration.Campaign.History}/${id}`
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  DocumentHistoryByCriteriaRequestModel,
  DocumentHistoryByCriteriaResponseModel,
} from '../../models/document-history.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class DocumentHistoryFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: DocumentHistoryByCriteriaRequestModel
  ): Observable<DocumentHistoryByCriteriaResponseModel> {
    return this.httpClient.get<DocumentHistoryByCriteriaResponseModel>(
      `${
        Endpoint.Customer.DocumentHistory.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  CreateRoleRequestModel,
  CreateRoleResponseModel,
  HistoryRoleResponseModel,
  RoleByCriteriaRequestModel,
  RoleByCriteriaResponseModel,
  RoleByIdResponseModel,
  RoleLookupResponseModel,
  UpdateRoleRequestModel,
  UpdateRoleResponseModel,
} from '../../models/role.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class RoleFetchService {
  constructor(private httpClient: HttpClient) {}

  public lookup(): Observable<RoleLookupResponseModel> {
    return this.httpClient.get<RoleLookupResponseModel>(
      Endpoint.Identity.Role.Lookup
    );
  }

  public read(id: string): Observable<RoleByIdResponseModel> {
    return this.httpClient.get<RoleByIdResponseModel>(
      `${Endpoint.Identity.Role.Common}/${id}`
    );
  }

  public criteria(
    query: RoleByCriteriaRequestModel
  ): Observable<RoleByCriteriaResponseModel> {
    return this.httpClient.get<RoleByCriteriaResponseModel>(
      `${
        Endpoint.Identity.Role.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public create(
    data: CreateRoleRequestModel
  ): Observable<CreateRoleResponseModel> {
    return this.httpClient.post<CreateRoleResponseModel>(
      Endpoint.Identity.Role.Common,
      data
    );
  }

  public update(
    id: string,
    data: UpdateRoleRequestModel
  ): Observable<UpdateRoleResponseModel> {
    return this.httpClient.put<UpdateRoleResponseModel>(
      `${Endpoint.Identity.Role.Common}/${id}`,
      data
    );
  }

  public history(id: string): Observable<HistoryRoleResponseModel> {
    return this.httpClient.get<HistoryRoleResponseModel>(
      `${Endpoint.Identity.Role.History}/${id}`
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  ComplianceRedFlagByCriteriaRequestModel,
  ComplianceRedFlagByCriteriaResponseModel,
  ComplianceRedFlagGroupByCriteriaRequestModel,
  ComplianceRedFlagGroupByCriteriaResponseModel,
  PatchComplianceRedFlagRequestModel,
  PatchComplianceRedFlagResponseModel,
} from '../../models/compliance-red-flag.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class ComplianceRedFlagFetchService {
  constructor(private httpClient: HttpClient) {}

  public groupCriteria(
    query: ComplianceRedFlagGroupByCriteriaRequestModel
  ): Observable<ComplianceRedFlagGroupByCriteriaResponseModel> {
    return this.httpClient.get<ComplianceRedFlagGroupByCriteriaResponseModel>(
      `${
        Endpoint.Customer.ComplianceRedFlag.Group
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public criteria(
    query: ComplianceRedFlagByCriteriaRequestModel
  ): Observable<ComplianceRedFlagByCriteriaResponseModel> {
    return this.httpClient.get<ComplianceRedFlagByCriteriaResponseModel>(
      `${
        Endpoint.Customer.ComplianceRedFlag.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public patch(
    id: string,
    data: PatchComplianceRedFlagRequestModel
  ): Observable<PatchComplianceRedFlagResponseModel> {
    return this.httpClient.patch<PatchComplianceRedFlagResponseModel>(
      `${Endpoint.Customer.ComplianceRedFlag.Common}/${id}`,
      data
    );
  }
}

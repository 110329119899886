/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { CookieService } from 'ngx-cookie-service';

import { Action, NgRxState } from '@xbaht/common';
import { AuthenticationFetchService } from '@xbaht/common/fetches';

import { CookieStorage } from '../constants/storage';

@Injectable({
  providedIn: 'root',
})
export class ErrorFetchService implements HttpInterceptor {
  constructor(
    private router: Router,
    private ngrxStore: Store<NgRxState>,
    private cookieService: CookieService,
    private authenticationFetchService: AuthenticationFetchService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.authenticationFetchService
            .signOut()
            .pipe(first())
            .subscribe((response) => {
              if (response.status) {
                this.router.navigate(['', 'auth', 'sign-in'], {
                  replaceUrl: true,
                });
              }

              this.cookieService.delete(CookieStorage.token);

              this.ngrxStore.dispatch(Action.AuthAction.ResetAll());
            });
        }

        return throwError(() => err);
      })
    );
  }
}

export const ErrorFetchProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorFetchService,
  multi: true,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'find',
})
export class FindPipe implements PipeTransform {
  transform(values: any, ...args: any[]): any {
    console.log(values, args);

    const predicate = (value: any) => {
      for (let index = 0; index < args.length; index++) {
        for (const key in args[index]) {
          if (value[key] !== args[index][key]) return false;
        }
      }

      return true;
    };

    return values.find(predicate);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  RecipientByCriteriaRequestModel,
  RecipientByCriteriaResponseModel,
} from '../../models/recipient.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class RecipientFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: RecipientByCriteriaRequestModel
  ): Observable<RecipientByCriteriaResponseModel> {
    return this.httpClient.get<RecipientByCriteriaResponseModel>(
      `${
        Endpoint.Customer.Recipient.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }
}

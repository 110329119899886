import { createAction, props } from '@ngrx/store';
import { PermissionModel } from '../../../models/authentication.model';

export const AUTH_SET_EMAIL = '[AUTH][SET]::[Email]';
export const AUTH_SET_VERIFIED = '[AUTH][SET]::[Verified]';
export const AUTH_SET_REMEMBERED = '[AUTH][SET]::[Remembered]';
export const AUTH_SET_INFO = '[AUTH][SET]::[Info]';
export const AUTH_SET_PERMISSION = '[AUTH][SET]::[Permission]';
export const AUTH_SET_TOKEN = '[AUTH][SET]::[Token]';
export const AUTH_RESET_ALL = '[AUTH][RESET]::[All]';

export const SetEmail = createAction(
  AUTH_SET_EMAIL,
  props<{ email: string }>()
);
export const SetVerified = createAction(
  AUTH_SET_VERIFIED,
  props<{ verified: boolean }>()
);
export const SetRemembered = createAction(
  AUTH_SET_REMEMBERED,
  props<{ remembered: boolean }>()
);
export const SetInfo = createAction(
  AUTH_SET_INFO,
  props<{
    firstname: string;
    lastname: string;
    email: string;
    roles: string[];
  }>()
);
export const SetPermission = createAction(
  AUTH_SET_PERMISSION,
  props<{ permissions: PermissionModel[] }>()
);
export const SetToken = createAction(
  AUTH_SET_TOKEN,
  props<{ token: string }>()
);
export const ResetAll = createAction(AUTH_RESET_ALL);

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'word',
})
export class WordPipe implements PipeTransform {
  transform(value: string, type: string): string {
    switch (type) {
      case 'title':
        // eslint-disable-next-line no-case-declarations
        const result = value.replace(/([A-Z])/g, ' $1');

        return result.charAt(0).toUpperCase() + result.slice(1);
      default:
        return value;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  CreateDocumentNoteRequestModel,
  CreateDocumentNoteResponseModel,
  DocumentNoteByCriteriaRequestModel,
  DocumentNoteByCriteriaResponseModel,
} from '../../models/document-note.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class DocumentNoteFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: DocumentNoteByCriteriaRequestModel
  ): Observable<DocumentNoteByCriteriaResponseModel> {
    return this.httpClient.get<DocumentNoteByCriteriaResponseModel>(
      `${
        Endpoint.Customer.DocumentNote.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public create(
    data: CreateDocumentNoteRequestModel
  ): Observable<CreateDocumentNoteResponseModel> {
    return this.httpClient.post<CreateDocumentNoteResponseModel>(
      Endpoint.Customer.DocumentNote.Common,
      data
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  ComplianceKycByCriteriaRequestModel,
  ComplianceKycByCriteriaResponseModel,
  CreateComplianceKycBatchRequestModel,
  CreateComplianceKycBatchResponseModel,
  CreateComplianceKycRequestModel,
  CreateComplianceKycResponseModel,
  PatchComplianceKycRequestModel,
  PatchComplianceKycResponseModel,
} from '../../models/compliance-kyc.model';

import { Endpoint } from '../constants/endpoint';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class ComplianceKycFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: ComplianceKycByCriteriaRequestModel
  ): Observable<ComplianceKycByCriteriaResponseModel> {
    return this.httpClient.get<ComplianceKycByCriteriaResponseModel>(
      `${
        Endpoint.Customer.ComplianceKyc.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public create(
    data: CreateComplianceKycRequestModel
  ): Observable<CreateComplianceKycResponseModel> {
    return this.httpClient.post<CreateComplianceKycResponseModel>(
      Endpoint.Customer.ComplianceKyc.Common,
      data
    );
  }

  public createBatch(
    data: CreateComplianceKycBatchRequestModel
  ): Observable<CreateComplianceKycBatchResponseModel> {
    return this.httpClient.post<CreateComplianceKycBatchResponseModel>(
      Endpoint.Customer.ComplianceKyc.Batch,
      data
    );
  }

  public patch(
    requestNo: string,
    customerId: string,
    data: PatchComplianceKycRequestModel
  ): Observable<PatchComplianceKycResponseModel> {
    return this.httpClient.patch<PatchComplianceKycResponseModel>(
      `${Endpoint.Customer.ComplianceKyc.Common}/${requestNo}/${customerId}`,
      data
    );
  }
}

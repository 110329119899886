import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  CreateExchangeRateRequestModel,
  CreateExchangeRateResponseModel,
  ExchangeRateByCriteriaRequestModel,
  ExchangeRateByCriteriaResponseModel,
  HistoryExchangeRateResponseModel,
  UpdateExchangeRateRequestModel,
  UpdateExchangeRateResponseModel,
} from '../../models/exchange-rate.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class ExchangeRateFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: ExchangeRateByCriteriaRequestModel
  ): Observable<ExchangeRateByCriteriaResponseModel> {
    return this.httpClient.get<ExchangeRateByCriteriaResponseModel>(
      `${
        Endpoint.Configuration.ExchangeRate.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public create(
    data: CreateExchangeRateRequestModel
  ): Observable<CreateExchangeRateResponseModel> {
    return this.httpClient.post<CreateExchangeRateResponseModel>(
      Endpoint.Configuration.ExchangeRate.Common,
      data
    );
  }

  public update(
    id: string,
    data: UpdateExchangeRateRequestModel
  ): Observable<UpdateExchangeRateResponseModel> {
    return this.httpClient.put<UpdateExchangeRateResponseModel>(
      `${Endpoint.Configuration.ExchangeRate.Common}/${id}`,
      data
    );
  }

  public history(id: string): Observable<HistoryExchangeRateResponseModel> {
    return this.httpClient.get<HistoryExchangeRateResponseModel>(
      `${Endpoint.Configuration.ExchangeRate.History}/${id}`
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  CountryByCriteriaRequestModel,
  CountryByCriteriaResponseModel,
  CreateCountryRequestModel,
  CreateCountryResponseModel,
  HistoryCountryResponseModel,
  PatchCountryRequestModel,
  PatchCountryResponseModel,
  UpdateCountryRequestModel,
  UpdateCountryResponseModel,
} from '../../models/country.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class CountryFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: CountryByCriteriaRequestModel
  ): Observable<CountryByCriteriaResponseModel> {
    return this.httpClient.get<CountryByCriteriaResponseModel>(
      `${
        Endpoint.Configuration.Country.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public create(
    data: CreateCountryRequestModel
  ): Observable<CreateCountryResponseModel> {
    return this.httpClient.post<CreateCountryResponseModel>(
      Endpoint.Configuration.Country.Common,
      data
    );
  }

  public update(
    id: string,
    data: UpdateCountryRequestModel
  ): Observable<UpdateCountryResponseModel> {
    return this.httpClient.put<UpdateCountryResponseModel>(
      `${Endpoint.Configuration.Country.Common}/${id}`,
      data
    );
  }

  public patch(
    id: string,
    data: PatchCountryRequestModel
  ): Observable<PatchCountryResponseModel> {
    return this.httpClient.patch<PatchCountryResponseModel>(
      `${Endpoint.Configuration.Country.Common}/${id}`,
      data
    );
  }

  public history(id: string): Observable<HistoryCountryResponseModel> {
    return this.httpClient.get<HistoryCountryResponseModel>(
      `${Endpoint.Configuration.Country.History}/${id}`
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventFetchProvider, LoggingFetchProvider } from '../fetches';

import { environment } from 'environment';

@NgModule({
  imports: [CommonModule],
  providers: [
    !environment.production ? LoggingFetchProvider : [],
    EventFetchProvider,
  ],
})
export class AppFetchModule {}

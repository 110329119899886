import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { EventFetchService } from './interceptors/event-fetch.service';
import { LoggingFetchService } from './interceptors/logging-fetch.service';

export const LoggingFetchProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoggingFetchService,
  multi: true,
};

export const EventFetchProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: EventFetchService,
  multi: true,
};

export { AuthenticationFetchService } from './services/authentication-fetch.service';
export { UserFetchService } from './services/user-fetch.service';
export { RoleFetchService } from './services/role-fetch.service';
export { MasterFetchService } from './services/master-fetch.service';
export { CountryFetchService } from './services/country-fetch.service';
export { ExchangeRateFetchService } from './services/exchange-rate-fetch.service';
export { TransactionFeeFetchService } from './services/transaction-fee-fetch.service';
export { SystemLimitFetchService } from './services/system-limit-fetch.service';
export { PayoutPartnerFetchService } from './services/payout-partner-fetch.service';
export { CampaignFetchService } from './services/campaign-fetch.service';
export { CustomerFetchService } from './services/customer-fetch.service';
export { ActivityLogFetchService } from './services/activity-log-fetch.service';
export { OrderFetchService } from './services/order-fetch.service';
export { RecipientFetchService } from './services/recipient-fetch.service';
export { ApplicationFetchService } from './services/application-fetch.service';
export { NotificationFetchService } from './services/notification-fetch.service';
export { IDkollenFetchService } from './services/idkollen-fetch.service';
export { ComplianceRedFlagFetchService } from './services/compliance-red-flag-fetch.service';
export { ComplianceKycFetchService } from './services/compliance-kyc-fetch.service';
export { DocumentNoteFetchService } from './services/document-note-fetch.service';
export { DocumentHistoryFetchService } from './services/document-history-fetch.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  ActivityLogByCriteriaRequestModel,
  ActivityLogByCriteriaResponseModel,
  ActivityLogLookupResponseModel,
} from '../../models/activity-log.model';

import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class ActivityLogFetchService {
  constructor(private httpClient: HttpClient) {}

  public lookup(): Observable<ActivityLogLookupResponseModel> {
    return this.httpClient.get<ActivityLogLookupResponseModel>(
      Endpoint.Log.History.Lookup
    );
  }

  public criteria(
    query: ActivityLogByCriteriaRequestModel
  ): Observable<ActivityLogByCriteriaResponseModel> {
    return this.httpClient.get<ActivityLogByCriteriaResponseModel>(
      `${Endpoint.Log.History.Common}${TransformTool.ConvertObjectToQueryString(
        query
      )}`
    );
  }
}

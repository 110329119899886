import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import * as dayjs from 'dayjs';

import { Endpoint } from '../constants/endpoint';

import {
  ExportOrderRequestModel,
  HistoryOrderResponseModel,
  OrderByCriteriaRequestModel,
  OrderByCriteriaResponseModel,
  OrderByIdResponseModel,
  OrderCustomerResponseModel,
  OrderPayInByCriteriaRequestModel,
  OrderPayInByCriteriaResponseModel,
  OrderPayOutByCriteriaRequestModel,
  OrderPayOutByCriteriaResponseModel,
  PatchOrderRequestModel,
  RefundOrderRequestModel,
  RefundOrderResponseModel,
  ResendOrderResponseModel,
  VerifyOrderResponseModel,
} from '../../models/order.model';

import { TransformTool } from '../tools/transform.tool';
import { ContextKey } from '../constants/context';

@Injectable({
  providedIn: 'root',
})
export class OrderFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: OrderByCriteriaRequestModel
  ): Observable<OrderByCriteriaResponseModel> {
    return this.httpClient.get<OrderByCriteriaResponseModel>(
      `${
        Endpoint.Transaction.Order.Orders
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public detail(id: string): Observable<OrderByIdResponseModel> {
    return this.httpClient.get<OrderByIdResponseModel>(
      `${Endpoint.Transaction.Order.Common}/${id}`
    );
  }

  public payIn(
    orderNo: string,
    query: OrderPayInByCriteriaRequestModel
  ): Observable<OrderPayInByCriteriaResponseModel> {
    return this.httpClient.get<OrderPayInByCriteriaResponseModel>(
      `${Endpoint.Transaction.Order.PatIn.replace(
        '{orderNo}',
        orderNo
      )}${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public payOut(
    orderNo: string,
    query: OrderPayOutByCriteriaRequestModel
  ): Observable<OrderPayOutByCriteriaResponseModel> {
    return this.httpClient.get<OrderPayOutByCriteriaResponseModel>(
      `${Endpoint.Transaction.Order.PatOut.replace(
        '{orderNo}',
        orderNo
      )}${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public summary(id: string): Observable<OrderCustomerResponseModel> {
    return this.httpClient.get<OrderCustomerResponseModel>(
      `${Endpoint.Transaction.Order.Customer}/${id}`
    );
  }

  public patch(
    id: string,
    data: PatchOrderRequestModel
  ): Observable<OrderPayOutByCriteriaResponseModel> {
    return this.httpClient.patch<OrderPayOutByCriteriaResponseModel>(
      `${Endpoint.Transaction.Order.Common}/${id}`,
      data
    );
  }

  public export(query: ExportOrderRequestModel): Observable<{
    content_type: string;
    enable_range_processing: boolean;
    entity_tag: string | null;
    file_contents: string;
    file_download_name: string;
    last_modified: string | null;
  }> {
    return this.httpClient.get<{
      content_type: string;
      enable_range_processing: boolean;
      entity_tag: string | null;
      file_contents: string;
      file_download_name: string;
      last_modified: string | null;
    }>(
      `${
        Endpoint.Transaction.Order.Export
      }${TransformTool.ConvertObjectToQueryString(query)}`,
      {
        headers: {
          'Content-Type': '*/csv',
          FileName: `${dayjs().format('YYYYMMDDHHmmssSSS')}.csv`,
          'X-Timezone-Offset': dayjs()
            .format('Z')
            .replace('+', '')
            .replace(':', '.'),
        },
        context: new HttpContext().set(ContextKey.ApiEncryption, false),
      }
    );
  }

  public refund(
    data: RefundOrderRequestModel
  ): Observable<RefundOrderResponseModel> {
    return this.httpClient.patch<RefundOrderResponseModel>(
      `${Endpoint.Transaction.Order.Refund}/${data.id}`,
      data
    );
  }

  public verify(orderOn: string): Observable<VerifyOrderResponseModel> {
    return this.httpClient.patch<VerifyOrderResponseModel>(
      `${Endpoint.Transaction.Order.Verify}/${orderOn}`,
      null
    );
  }

  public resend(id: string): Observable<ResendOrderResponseModel> {
    return this.httpClient.post<ResendOrderResponseModel>(
      `${Endpoint.Transaction.Order.Resend}/${id}`,
      null
    );
  }

  public history(id: string): Observable<HistoryOrderResponseModel> {
    return this.httpClient.get<HistoryOrderResponseModel>(
      `${Endpoint.Transaction.Order.History}/${id}`
    );
  }
}

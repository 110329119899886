import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { catchError, first, throwError } from 'rxjs';

import { CountryFetchService } from '../../fetches/services/country-fetch.service';
import { CountryModel } from '../../models/country.model';

@Directive({
  selector: '[xbahtCountry]',
})
export class CountryDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<{
      $implicit: CountryModel;
      index: number;
      first: boolean;
      last: boolean;
      even: boolean;
      odd: boolean;
      count: number;
    }>,
    private viewContainer: ViewContainerRef,
    private countryFetchService: CountryFetchService
  ) {}

  ngOnInit(): void {
    this.countryFetchService
      .criteria({ offset: 0, limit: 999, sby: 'Name', stype: 'asc' })
      .pipe(
        first(),
        catchError((error) => throwError(() => error))
      )
      .subscribe((item) => {
        if (item.status && item.payload) {
          this.viewContainer.clear();
          for (let index = 0; index < item.payload.length; index++) {
            this.viewContainer.createEmbeddedView(this.templateRef, {
              $implicit: item.payload[index],
              index,
              first: index === 0,
              last: index === item.payload.length - 1,
              even: (index & 1) === 0,
              odd: (index & 1) === 1,
              count: item.payload?.length,
            });
          }
        }
      });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import { TransformTool } from '../tools/transform.tool';
import {
  CreateNotificationRequestModel,
  CreateNotificationResponseModel,
  HistoryNotificationResponseModel,
  NotificationByCriteriaRequestModel,
  NotificationByCriteriaResponseModel,
  PatchNotificationRequestModel,
  PatchNotificationResponseModel,
  UpdateNotificationRequestModel,
  UpdateNotificationResponseModel,
} from '../../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationFetchService {
  constructor(private httpClient: HttpClient) {}

  public criteria(
    query: NotificationByCriteriaRequestModel
  ): Observable<NotificationByCriteriaResponseModel> {
    return this.httpClient.get<NotificationByCriteriaResponseModel>(
      `${
        Endpoint.Configuration.Notification.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public create(
    data: CreateNotificationRequestModel
  ): Observable<CreateNotificationResponseModel> {
    return this.httpClient.post<CreateNotificationResponseModel>(
      Endpoint.Configuration.Notification.Common,
      data
    );
  }

  public update(
    id: string,
    data: UpdateNotificationRequestModel
  ): Observable<UpdateNotificationResponseModel> {
    return this.httpClient.put<UpdateNotificationResponseModel>(
      `${Endpoint.Configuration.Notification.Common}/${id}`,
      data
    );
  }

  public patch(
    id: string,
    data: PatchNotificationRequestModel
  ): Observable<PatchNotificationResponseModel> {
    return this.httpClient.patch<PatchNotificationResponseModel>(
      `${Endpoint.Configuration.Notification.Common}/${id}`,
      data
    );
  }

  public history(id: string): Observable<HistoryNotificationResponseModel> {
    return this.httpClient.get<HistoryNotificationResponseModel>(
      `${Endpoint.Configuration.Notification.History}/${id}`
    );
  }
}

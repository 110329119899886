import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  routerReducer,
  RouterReducerState,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import {
  StoreDevtoolsModule,
  StoreDevtoolsOptions,
} from '@ngrx/store-devtools';

import { AppAction, AuthAction, MasterAction } from '../stored/global/actions';
import {
  AppReducer,
  AuthReducer,
  MasterReducer,
} from '../stored/global/reducers';
import { AppEffect, AuthEffect, MasterEffect } from '../stored/global/effects';
import { AppState, AuthState, MasterState } from '../stored/global/states';

import { environment } from 'environment';

export { AppState, AuthState, MasterState };

interface NgRxState {
  Router: RouterReducerState;
  App: AppState;
  Auth: AuthState;
  Master: MasterState;
}

const NgRxReducer = {
  Router: routerReducer,
  App: AppReducer,
  Auth: AuthReducer,
  Master: MasterReducer,
};

const NgRxEffects = [AppEffect, AuthEffect, MasterEffect];

const Reducer = {
  routerReducer,
  AppReducer,
  AuthReducer,
  MasterReducer,
};

const Action = { AppAction, AuthAction, MasterAction };

export { NgRxState, NgRxReducer, NgRxEffects, Reducer, Action };

const storeDevOpts: StoreDevtoolsOptions = {
  maxAge: 32,
  logOnly: false,
};

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(NgRxReducer),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(NgRxEffects),
    !environment.production ? StoreDevtoolsModule.instrument(storeDevOpts) : [],
  ],
  exports: [
    StoreModule,
    StoreRouterConnectingModule,
    EffectsModule,
    !environment.production ? StoreDevtoolsModule : [],
  ],
})
export class AppNgrxModule {}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import {
  AppLocalizationModule,
  AppFetchModule,
  AppNgrxModule,
} from '@xbaht/common';

import { InitializeProvider } from './initialize';

import { ErrorFetchProvider } from './utils/interceptors/error-fetch.service';

import { AppRoutingModule } from './modules';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppLocalizationModule,
    AppFetchModule,
    AppNgrxModule,
    AppRoutingModule,
  ],
  providers: [InitializeProvider, ErrorFetchProvider],
  bootstrap: [AppComponent],
})
export class AppModule {}

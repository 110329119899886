import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasterCountryDirective } from '../directives/masters/country/master-country.directive';
import { MasterCurrencyDirective } from '../directives/masters/currency/master-currency.directive';
import { MasterPayoutMethodDirective } from '../directives/masters/payout-method/master-payout-method.directive';

import { MasterPartnerDirective } from '../directives/masters/partner/master-partner.directive';
import { MasterExpressionDirective } from '../directives/masters/expression/master-expression.directive';
import { MasterPaymentMethodDirective } from '../directives/masters/payment-method/master-payment-method.directive';

import { CountryDirective } from '../directives/country/country.directive';

import { MonthlyPaymentDirective } from '../directives/masters/monthly-payment/monthly-payment.directive';
import { OccupationDirective } from '../directives/masters/occupation/occupation.directive';
import { PurposeDirective } from '../directives/masters/purpose/purpose.directive';
import { RegularityDirective } from '../directives/masters/regularity/regularity.directive';
import { RiskLevelDirective } from '../directives/masters/risk-level/risk-level.directive';
import { CustomerTagDirective } from '../directives/masters/customer-tag/customer-tag.directive';
import { UpdateRequestDirective } from '../directives/masters/update-request/update-request.directive';
import { TimeRequestDirective } from '../directives/masters/time-request/time-request.directive';
import { DocumentRequestDirective } from '../directives/masters/document-request/document-request.directive';

@NgModule({
  declarations: [
    MasterCountryDirective,
    MasterCurrencyDirective,
    MasterPayoutMethodDirective,
    MasterPartnerDirective,
    MasterExpressionDirective,
    MasterPaymentMethodDirective,
    CountryDirective,
    MonthlyPaymentDirective,
    OccupationDirective,
    PurposeDirective,
    RegularityDirective,
    RiskLevelDirective,
    CustomerTagDirective,
    UpdateRequestDirective,
    TimeRequestDirective,
    DocumentRequestDirective,
  ],
  imports: [CommonModule],
  exports: [
    MasterCountryDirective,
    MasterCurrencyDirective,
    MasterPayoutMethodDirective,
    MasterPartnerDirective,
    MasterExpressionDirective,
    MasterPaymentMethodDirective,
    CountryDirective,
    MonthlyPaymentDirective,
    OccupationDirective,
    PurposeDirective,
    RegularityDirective,
    RiskLevelDirective,
    CustomerTagDirective,
    UpdateRequestDirective,
    TimeRequestDirective,
    DocumentRequestDirective,
  ],
})
export class AppDirectiveModule {}

import { Action, createReducer, on } from '@ngrx/store';

import { MasterAction } from '../actions';
import { MasterState } from '../states/master.state';

const initialState: MasterState = {
  countries: [],
  currencies: [],
  payout_methods: [],
  partners: [],
  expressions: [],
  payment_methods: [],
  monthly_payments: [],
  occupations: [],
  purposes: [],
  reguarities: [],
  risk_levels: [],
  customer_tags: [],
  update_requests: [],
  time_requests: [],
  document_requests: [],
};

const masterReducer = createReducer(
  initialState,
  on(MasterAction.SetCountry, (state, { countries }) => ({
    ...state,
    countries,
  })),
  on(MasterAction.SetCurrency, (state, { currencies }) => ({
    ...state,
    currencies,
  })),
  on(MasterAction.SetPayoutMethod, (state, { payout_methods }) => ({
    ...state,
    payout_methods,
  })),
  on(MasterAction.SetPartner, (state, { partners }) => ({
    ...state,
    partners,
  })),
  on(MasterAction.SetExpression, (state, { expressions }) => ({
    ...state,
    expressions,
  })),
  on(MasterAction.SetPaymentMethod, (state, { payment_methods }) => ({
    ...state,
    payment_methods,
  })),
  on(MasterAction.SetMonthlyPayment, (state, { monthly_payments }) => ({
    ...state,
    monthly_payments,
  })),
  on(MasterAction.SetOccupation, (state, { occupations }) => ({
    ...state,
    occupations,
  })),
  on(MasterAction.SetPurpose, (state, { purposes }) => ({
    ...state,
    purposes,
  })),
  on(MasterAction.SetRegularity, (state, { reguarities }) => ({
    ...state,
    reguarities,
  })),
  on(MasterAction.SetRiskLevel, (state, { risk_levels }) => ({
    ...state,
    risk_levels,
  })),
  on(MasterAction.SetCustomerTag, (state, { customer_tags }) => ({
    ...state,
    customer_tags,
  })),
  on(MasterAction.SetUpdateRequest, (state, { update_requests }) => ({
    ...state,
    update_requests,
  })),
  on(MasterAction.SetTimeRequest, (state, { time_requests }) => ({
    ...state,
    time_requests,
  })),
  on(MasterAction.SetDocumentRequest, (state, { document_requests }) => ({
    ...state,
    document_requests,
  }))
);

export function MasterReducer(state: MasterState | undefined, action: Action) {
  return masterReducer(state, action);
}
